@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Rajdhani&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Karla:ital,wght@0,200..800;1,200..800&family=Noto+Sans+KR:wght@100..900&family=Playwrite+HR+Lijeva+Guides&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Slabo+27px&display=swap');
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* *{
  font-family: 'Poppins', sans-serif !important;
} */
*{
  font-family: "Karla", serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.slick-prev::before{
  color: #3e4877 !important;
  font-size: 40px !important;
}
.slick-prev{
  left: -45px !important;
}
.slick-next::before{
  color: #3e4877 !important;
  font-size: 40px !important;
}
@media only screen and (max-width:600px){
  .slick-prev{
    display: none !important;
  }
  .slick-next{
    display: none !important;
  }
}

.slick-slide > div{
  margin: 0 10px;
}

.animate-bounce {
  animation: bounce 3s infinite;
}
/* styles.css */
.fixed-height-ellipsis {
  display: block;
  width: 100%; /* Adjust width as needed */
  height: 150px; /* Adjust height as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: unset; /* Unset the line clamp */
  position: relative;
}

.fixed-height-ellipsis::after {
  content: '...';
  position: absolute;
  bottom: 0;
  right: 0;
  /* background: white; Match the background color */
  /* padding-left: 10px; */
}


@keyframes bounce {
  0%, 100% {
      transform: translateY(-4%);
      animation-timing-function: cubic-bezier(0.8,0,1,1);
  }
  50% {
      transform: none;
      animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@media (min-width: 1024px) {
  .text {
      line-height: 2;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      text-shadow: 2px 2px 4px black;
    letter-spacing: 2px;
   
  }
}
 .waveMiddle {
  animation: move_wave 10s linear infinite;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: move_wave;
  animation-timeline: auto;
  animation-range-start: normal;
  animation-range-end: normal;
}
@keyframes ping {
  75%, 100% {
      transform: scale(2);
      opacity: 0;
  }
}
.animate-ping {
  animation: ping 3s cubic-bezier(0.1, 0, 0.5, 0.1) infinite;
}
@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 15s linear infinite;
}
h1{
  font-size: larger;
}
h2{
  font-size: 40px;
}
h3{
  font-size: 40px;
  color: #7150d4;
}
strong{
  color: #0d6dfc;
}
.font_in_contact{
  font-family: "Times New Roman", Times, serif;
}
